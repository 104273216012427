<template>
  <base-section id="about-our-product">
    <base-section-heading
      color="grey lighten-2"
      icon="mdi-account-box-outline"
      title="About Us"
    />

    <v-container>
      <v-row>
        <v-col
          v-for="card in cards"
          :key="card.title"
          cols="12"
          md="4"
        >
          <base-info-card v-bind="card" />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionAboutOurProduct',

    data: () => ({
      cards: [
        {
          title: 'SelfTrained Designers',
          subtitle: 'Power of Teamwork',
          // text: 'Similique sunt in culpa qui officia deserunt mollitia animi, id est laborut dolorum fuga.harum quidem rerum facilis estexpedita distinctio.',
          text: 'We are a team of self-trained designers and we make our deliverables speak for us. We keep challenging the corporate designers with our fresh and unique designs and keep improvising it day by day.',
          callout: '01',
        },
        {
          title: 'Affordable Prices',
          subtitle: 'Special Offers',
          // text: 'Similique sunt in culpa qui officia deserunt mollitia animi, id est laborut dolorum fuga.harum quidem rerum facilis estexpedita distinctio.',
          text: 'We offer services in "value-based" pricing. You always know what you will pay. Our prices will be suitable for all scales of businesses. We also provide discounts and offers during festive seasons and on referrals',
          callout: '02',
        },
        {
          title: 'Creative At The Core',
          subtitle: 'Customer Centric',
          text: 'We act as design partner and recommend insights on your brand promotion and customer reach. We focus on creativity and customer centric designs that help businesses to get the valued attention from their customers.',
          callout: '03',
        },
      ],
    }),
  }
</script>
